import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/EkBharatClub/DanceDrama/1.jpg';
// import p2 from 'assests/Photos/Clubs/EkBharatClub/DanceDrama/2.jpg';
// import p3 from 'assests/Photos/Clubs/EkBharatClub/DanceDrama/3.jpg';
// import p4 from 'assests/Photos/Clubs/EkBharatClub/DanceDrama/4.jpg';
// import p5 from 'assests/Photos/Clubs/EkBharatClub/DanceDrama/5.jpg';
// import p6 from 'assests/Photos/Clubs/EkBharatClub/DanceDrama/6.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from './components';


const EkBharat2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EkBharatClub/DanceDrama/1.webp`;
    const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EkBharatClub/DanceDrama/2.webp`;
    const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EkBharatClub/DanceDrama/3.webp`;
    const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EkBharatClub/DanceDrama/4.webp`;
    const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EkBharatClub/DanceDrama/5.webp`;
    const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/EkBharatClub/DanceDrama/6.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
         {
            src: p6,
            source: p6,
            rows: 3,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 2,
        },
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    Dance Drama
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: 6-8  Date: 15 AUGUST 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    National Public School, Yeshwanthpur celebrated 77th Independence Day on Tuesday 15th August 2023.To commemorate this joyous occasion, EK Bharat Shreshta Bharat Association organized Dance Drama, an interhouse competition for the students of classes 6-8.  The event showcased the rich cultural tapestry of the state of Karnataka and the Union Territory of Ladakh.
                                    <br></br>
                                    Students conveyed the vital message of unity in diversity and fraternity through their dynamic
                                    performances. The event left an indelible mark on the audience not only by entertaining them but
                                    also inspiring deeper appreciation for cultural richness that defines India as a nation.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “UNITY IN DIVERSITY CAN LEAD A NATION TO WHAT IT NEEDS”
                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box>


                        {/* <SidebarNewsletter /> */}
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default EkBharat2023;